












































import { Component, Vue, Watch } from "vue-property-decorator";
import dashboardModule from "@/store/modules/dashboard-module";
import agrupacion_importacionesDtoModule from "@/store/modules/agrupacion_importacionesDto-module";
import ayuntamientoModule from "@/store/modules/ayuntamiento-module";
import usuarioModule from "@/store/modules/usuario-module";
@Component({
  components: {
    GaugesChart: () =>
      import("@/components/Charts/GaugesChartAyuntamiento.vue"),
    GaugesChartNormal: () =>
      import("@/components/Charts/GaugesChartNormalAyuntamiento.vue"),
    DropDownMultipleSelectionList: () =>
      import("@/components/DropsDown/DropDownMultipleSelectionList.vue"),
  },
})
export default class DashboardAyuntamiento extends Vue {
  public ids_filtrados: number[] = [];
  public habilitar_filtros: boolean = false;

  public async created() {
    await agrupacion_importacionesDtoModule.getagrupacion_importacionesDtos();
    this.ids_filtrados = this.agrupaciones.map((x) => x.id);
    this.mi_usuario.ayuntamiento = this.ayuntamientos.find(
      (x) => x.id === this.mi_usuario.id_ayuntamiento
    )!;

    dashboardModule.getgaugesdashboards_ayuntamiento({
      agrupaciones: this.ids_filtrados,
      ayuntamientos: [this.mi_usuario.id_ayuntamiento],
    });

    dashboardModule.getgauge_general_ayuntamiento({
      agrupaciones: this.ids_filtrados,
      ayuntamientos: [this.mi_usuario.id_ayuntamiento],
    });

    this.habilitar_filtros = true;
  }

  public get mi_usuario() {
    return usuarioModule.mi_usuario;
  }

  public get dashboards() {
    //this.mi_usuario.id_ayuntamiento
    return dashboardModule.gauges_dashboard_ayuntamientos;
  }
  public get dashboardGeneral() {
    return dashboardModule.gauge_general_ayuntamiento;
  }

  public get agrupaciones() {
    return agrupacion_importacionesDtoModule.agrupacion_importacionesDtos;
  }
  public get ayuntamientos() {
    return ayuntamientoModule.ayuntamientos;
  }
  @Watch("ids_filtrados")
  public ChangeDataAgrupacion() {
    this.ChangeData();
  }

  public ChangeData() {
    if (!this.habilitar_filtros) {
      return;
    }

    dashboardModule.getgaugesdashboards_ayuntamiento({
      agrupaciones: this.ids_filtrados,
      ayuntamientos: [this.mi_usuario.id_ayuntamiento],
    });

    dashboardModule.getgauge_general_ayuntamiento({
      agrupaciones: this.ids_filtrados,
      ayuntamientos: [this.mi_usuario.id_ayuntamiento],
    });
  }
}
